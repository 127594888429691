:root {
  --control-minTarget-fine: 1rem;
  --control-minTarget-coarse: 2.75rem;
  --control-xsmall-size: 1.5rem;
  --control-xsmall-lineBoxHeight: 1.25rem;
  --control-xsmall-paddingBlock: calc((1.5rem - 1.25rem) / 2);
  --control-xsmall-paddingInline-condensed: 0.25rem;
  --control-xsmall-paddingInline-normal: 0.5rem;
  --control-xsmall-paddingInline-spacious: 0.75rem;
  --control-xsmall-gap: 0.25rem;
  --control-small-size: 1.75rem;
  --control-small-lineBoxHeight: 1.25rem;
  --control-small-paddingBlock: calc((1.75rem - 1.25rem) / 2);
  --control-small-paddingInline-condensed: 0.5rem;
  --control-small-paddingInline-normal: 0.75rem;
  --control-small-gap: 0.25rem;
  --control-medium-size: 2rem;
  --control-medium-lineBoxHeight: 1.25rem;
  --control-medium-paddingBlock: calc((2rem - 1.25rem) / 2);
  --control-medium-paddingInline-condensed: 0.5rem;
  --control-medium-paddingInline-normal: 0.75rem;
  --control-medium-paddingInline-spacious: 1rem;
  --control-medium-gap: 0.5rem;
  --control-large-size: 2.5rem;
  --control-large-lineBoxHeight: 1.25rem;
  --control-large-paddingBlock: calc((2.5rem - 1.25rem) / 2);
  --control-large-paddingInline-normal: 0.75rem;
  --control-large-paddingInline-spacious: 1rem;
  --control-large-gap: 0.5rem;
  --control-xlarge-size: 3rem;
  --control-xlarge-lineBoxHeight: 1.25rem;
  --control-xlarge-paddingBlock: calc((3rem - 1.25rem) / 2);
  --control-xlarge-paddingInline-normal: 0.75rem;
  --control-xlarge-paddingInline-spacious: 1rem;
  --control-xlarge-gap: 0.5rem;
  --controlStack-small-gap-condensed: 0.5rem;
  --controlStack-small-gap-spacious: 1rem;
  --controlStack-medium-gap-condensed: 0.5rem;
  --controlStack-medium-gap-spacious: 0.75rem;
  --controlStack-large-gap-auto: 0.5rem;
  --controlStack-large-gap-condensed: 0.5rem;
  --controlStack-large-gap-spacious: 0.75rem;
  --stack-padding-condensed: 0.5rem;
  --stack-padding-normal: 1rem;
  --stack-padding-spacious: 1.5rem;
  --stack-gap-condensed: 0.5rem;
  --stack-gap-normal: 1rem;
  --stack-gap-spacious: 1.5rem;
}
