:root {
  --boxShadow-thin: inset 0 0 0 max(1px, 0.0625rem);
  --boxShadow-thick: inset 0 0 0 max(2px, 0.125rem);
  --boxShadow-thicker: inset 0 0 0 max(4px, 0.25rem);
  --borderWidth-thin: max(1px, 0.0625rem);
  --borderWidth-thick: max(2px, 0.125rem);
  --borderWidth-thicker: max(4px, 0.25rem);
  --borderRadius-small: 3px;
  --borderRadius-medium: 6px;
  --borderRadius-large: 12px;
  --borderRadius-full: 100vh;
  --outline-focus-offset: -0.125rem;
  --outline-focus-width: 0.125rem;
}
